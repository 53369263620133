<template>
  <Toast position="top-right" />
  <div class="p-grid d-p-flex p-jc-center" style="">
    <div class="" style="max-width:650px; width:100%; margin:auto; margin-top: 80px;">
      <div class="text-title p-fluid p-text-center" style="margin: 10px 0px 20px 0px;">
        <h2 class="p-mb-0" style="color:#424242; font-weight: 500;">Recuperación de clave</h2>
      </div>
      <div class="card p-shadow p-radius p-pt-5" style="width:100%;">
        
        <form @submit.prevent="vericicar_acceso">
          <div class="contenedor p-px-2 p-pb-3 p-d-flex p-jc-around">
            <div class="contenedor-imagen p-d-flex p-fluid p-d-flex p-ai-center p-jc-center">
              <img 
                src="assets/layout/images/logo_login.png" 
                style="height:200px;" />
            </div>

            <div class="p-px-1">
              <div 
                class="p-d-flex p-jc-around p-ai-center p-pl-3 p-pb-2"
                style="width:100%;">
                <Button 
                  class="p-button-outlined p-button-rounded p-button-primary p-button-sm" 
                  icon="pi pi-send">
                </Button>
                <div class="progress" style="flex-grow:3;">
                  <div 
                    :class="{ 'full-width': step >= 2 }"
                    class="progress-bar">
                  </div>
                </div>
                <Button 
                  v-bind:class="{ 'p-button-secondary' : step < 2 }"
                  class="p-button-outlined p-button-rounded p-button-sm" 
                  icon="pi pi-ellipsis-h">
                </Button>
                <div class="progress" style="flex-grow:3;">
                  <div 
                    :class="{ 'full-width': step == 3 }"
                    class="progress-bar">
                  </div>
                </div>
                <Button 
                  v-bind:class="{ 'p-button-secondary': step != 3 }"
                  class="p-button-outlined p-button-rounded p-button-sm" 
                  icon="pi pi-unlock">
                </Button>
              </div>

              <!-- step 1 -->
              <div 
                v-if="step==1" 
                class="contenedor-form flex" 
                style="width:320px; padding: 0px 0px 0px 20px;">
                <p style="text-align: justify;" class="">
                  Para recuperar su contraseña es requerido verificar su identidad, enviaremos un código a su correo electrónico.
                </p>
                <small class="p-text-bold">Escriba su correo</small>
                <div class="p-fluid">
                  <div class="p-field p-fluid p-d-flex p-flex-column">
                    <span class="p-input-icon-right">
                      <InputText v-model="correo" placeholder="Ingrese su correo" />
                      <i class="pi pi-envelope" />
                    </span>
                    <small 
                      class="text-danger" 
                      v-bind:class="{ 'p-hidden': correo_is_valid }">
                      Ingrese un correo válido
                    </small>
                    <small 
                      class="text-danger" 
                      v-bind:class="{ 'p-hidden': !correo_empty }">
                      Debe ingresar el correo
                    </small>
                  </div>
                </div>
                <div class="p-fluid p-text-center">
                  <Button 
                    :loading="loading" 
                    type="submit" 
                    label="Enviar código" 
                    icon="pi pi-send"
                    iconPos="left" 
                    @click="verificar_correo" 
                    class="p-d-block" />
                </div>
                <div class="p-pt-2">
                  <router-link 
                    to="/" 
                    style="font-size:10pt;">Regresar
                  </router-link>
                </div>
              </div>

              <!-- step 2 -->
              <div 
                v-if="step==2" 
                class="contenedor-form flex" 
                style="width:320px; padding: 0px 0px 0px 20px;">
                <p style="text-align: justify;">
                  Verifique el codigo recibido en su correo y peguelo en el campo de texto para validar la información.
                </p>
                <div class="p-fluid">
                  <small class="p-text-bold">Escriba el código</small>
                  <div class="p-field p-fluid">
                    <span class="p-input-icon-right">
                      <InputText 
                        length="6"
                        v-model="codigo" 
                        placeholder="Ingrese el código" />
                      <i class="pi pi-ellipsis-h" />
                    </span>
                    <small 
                      class="text-danger" 
                      v-bind:class="{ 'p-hidden': !isNaN(codigo) }">
                      El código debe ser numerico.
                    </small>
                    <small 
                      class="text-danger" 
                      v-bind:class="{ 'p-hidden': !codigo_no_ingresado }">
                      Debe ingresar el código
                    </small>
                    <Message 
                      v-if="codigo_no_valido"
                      severity="error" 
                      :closable="false">
                      El código proporcionado no es válido o ya caduco.
                    </Message>
                  </div>
                </div>
                <div class="p-fluid p-text-center">
                  <Button 
                    :loading="loading" 
                    type="submit" 
                    label="Validar código" 
                    icon="pi pi-send"
                    iconPos="left" 
                    @click="verificar_codigo" 
                    class="p-d-block" />
                </div>
                <div class="p-pt-2">
                  <router-link to="/" style="font-size:10pt;">Volver a acceder</router-link>
                </div>
              </div>

              <!-- step 3 -->
              <div 
                v-if="step==3" 
                class="contenedor-form flex" 
                style="width:320px; padding: 0px 0px 0px 20px;">
                <p style="text-align: justify;" class="p-pt-1">
                  Defina su nueva contraseña
                </p>
                <div class="p-fluid">
                  <small class="p-text-bold">Escriba su contraseña</small>
                  <div class="p-field p-fluid">
                    <Password 
                      toggleMask 
                      v-model="clave_1" 
                      :feedback="false" 
                      placeholder="Contraseña" />
                    <small class="text-danger" v-bind:class="{ 'p-hidden': !clave_1_empty }">
                      Debe ingresar la contraseña
                    </small>
                  </div>
                  <small class="p-text-bold">Repita su contraseña</small>
                  <div class="p-field p-fluid p-d-flex p-flex-column">
                    <Password 
                      v-model="clave_2" 
                      :feedback="false" 
                      placeholder="Contraseña" />
                    <small 
                      class="text-danger"
                      v-bind:class="{ 'p-hidden': !clave_2_empty }">
                      Debe repetir la contraseña
                    </small>
                    <small 
                      class="text-danger"
                      v-bind:class="{ 'p-hidden': !claves_no_match }">
                      Las contraseñas no coinciden
                    </small>
                  </div>
                </div>
                <div class="p-fluid p-text-center">
                  <Button 
                    :loading="loading" 
                    type="submit" 
                    label="Actualizar contraseña" 
                    icon="pi pi-check-circle"
                    iconPos="left" 
                    @click="cambiar_clave" 
                    class="p-d-block" />
                </div>
                <div class="p-pt-2">
                  <router-link to="/" style="font-size:10pt;">Volver a acceder</router-link>
                </div>
              </div>
            </div>
            

          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
  .p-message-wrapper{
    padding: 12px 14px !important;
  }
  .p-message-icon{
    margin-right: 10px !important;
  }
  .progress {
    height: 2px !important;
    background: #d7d7d7;
  }
  .progress-bar {
    height: 2px !important;
    width: 0%;
    background: #007bff;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
  }
  .full-width {
    width: 100% !important;
  }
  .contenedor {
    display: flex;
    width: 100% !important;
  }
  .contenedor-imagen {
    min-width: 280px;
    width: 50%;
    border-right: 1px solid #DEE2E6;
  }
  .line{
    width: 40%;
    border-top:2px solid #d7d7d7 ;
    transition: .3s;
  }
  .line.step-active{
    border-top:2px solid #007bff !important ;
    transition: .3s;
  }
  @media screen and (max-width: 600px) {
    .text-title {
      margin: 20px 0px 0px 0px !important;
    }
    .contenedor {
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      width: 100% !important;
    }
    .contenedor-imagen {
      border: none !important;
      width: 100px !important;
    }
    .contenedor-form {
      width: 100% !important;
      padding: 10px 20px !important;
    }
  }
</style>

<script>
export default {
  data: () => {
    return {
      loading: false,
      codigo_cliente: 0,
      correo: '',
      codigo: '',
      clave_1 : '',
      clave_2 : '',
      step: 1,
      codigo_no_valido : false,
      codigo_no_ingresado : false,
      clave_1_empty : false,
      clave_2_empty : false,
      claves_no_match : false,
      correo_empty : false,
    }
  },
  methods: {
    async verificar_correo() {
      this.correo_empty = this.correo.length == 0;

      if( !this.correo_is_valid || this.correo.length == 0 )
        return false;

      this.loading = true;

      await this.axios.post("Login/EnviarCodigo/"+this.correo, this.f_login)
      .then(r => {
        console.log(r);
        let data = r.data;
        this.codigo_cliente = r.data.contenido;
        this.$toast.add({
          severity: (r.data.errores ? 'error' : 'success'),
          summary: 'Aviso',
          detail: r.data.mensaje,
          life: 2000
        })
        
        if(!data.errores){
          this.step = 2; 
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        this.loading = false;
      })
    },
    async verificar_codigo(){
      this.codigo_no_valido = false;
      this.codigo_no_ingresado = this.codigo.length == 0;

      if(this.codigo.length == ''){
        this.$toast.add({
          severity: 'error',
          summary: 'Aviso',
          detail: "Debe escribir el código",
          life: 2000
        })
        return false;
      }

      if(this.codigo.length == 0 || isNaN(this.codigo)){
        return false;
      }

      let payload = {
        codigo_cliente : this.codigo_cliente,
        clave : this.codigo
      }
      await this.axios.post("Login/ValidarCodigo", payload)
      .then(r => {
        console.log(r);
        let data = r.data;
        this.codigo_no_valido = data.estado == 0 && data.errores;
        this.codigo = data.estado == 0 && data.errores ? '' : this.codigo;
        if(!data.errores && data.estado == 1){
          this.$toast.add({
            severity: (r.data.errores ? 'error' : 'success'),
            summary: 'Aviso',
            detail: r.data.mensaje,
            life: 2000
          })
          this.step = 3; 
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: 'error',
          summary: 'Aviso',
          detail: "Algo ha salido mal, favor reintentar más tarde.",
          life: 2000
        })
      })
      .then(() => {
        this.loading = false;
      })
    },
    async cambiar_clave(){
      this.clave_1_empty = this.clave_1.length == 0;
      this.clave_2_empty = this.clave_2.length == 0;
      this.claves_no_match = this.clave_1 != this.clave_2;

      if(this.clave_1_empty || this.clave_2_empty || this.claves_no_match)
        return false;

      let payload = {
        codigo : this.codigo_cliente,
        clave : this.clave_1
      }

      await this.axios.post("Login/ActualizarClave", payload)
      .then(r => {
        console.log(r);
        let data = r.data;
        this.codigo_no_valido = data.estado == 0 && data.errores;
        
        if(!data.errores && data.estado == 1){
          this.$toast.add({
            severity: (r.data.errores ? 'error' : 'success'),
            summary: 'Aviso',
            detail: r.data.mensaje,
            life: 2000
          })
          setTimeout(() => {
            this.$router.push('/');
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: 'error',
          summary: 'Aviso',
          detail: "Algo ha salido mal, favor reintentar más tarde.",
          life: 2000
        })
      })
      .then(() => {
        this.loading = false;
      })
    },
  }, 
  computed: {
    correo_is_valid : function(){
			//eslint-disable-next-line
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
			return this.correo == '' ? true : re.test(this.correo)
		},
  }
}
</script>

<style scoped></style>
